import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import { IconProps } from './Icon';

const useStyles = makeStyles<Pick<IconProps, 'size'>>()((theme, { size = 'medium' }) => ({
	icon: {
		width: theme.icon.size[size],
		height: theme.icon.size[size],
		flexShrink: 0,
	},
}));

export default useStyles;
